import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '.app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
_activeFooter:boolean = false;
  constructor() { }

  ngOnInit() {
  }
  footerFun(){
    debugger;
    this._activeFooter = !this._activeFooter

  }
}
