import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommenService {
  private _activePage = new BehaviorSubject(0);  
  private _stopScrollactive = new BehaviorSubject(false);
  private _inFoActive = new BehaviorSubject(false);
  private _registerActive = new BehaviorSubject(false);
  _activePageNo = this._activePage.asObservable();   
  _inFoActiveFlag = this._inFoActive.asObservable(); 
  _registerActiveFlag = this._registerActive.asObservable(); 
  _stopScrollActive = this._stopScrollactive.asObservable();
  constructor() { }

  changePage(message: any) {
  
    this._activePage.next(message);
  }
  
  changestopScroll(message: any) {  
    this._stopScrollactive.next(message);
  } 
   changes_inFoActive(message: any) {  
    this._inFoActive.next(message);
  }

  changes_registerActive(message: any) {  
    this._registerActive.next(message);
  }

}
