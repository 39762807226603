import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { YgtPageDirective } from './scrollPage/ygt-page.directive';
import { FooterComponent } from './footer/footer.component';
import { SportsbettingComponent } from './sportsbetting/sportsbetting.component';
import { LotteryComponent } from './lottery/lottery.component';
import { VirtualgamesComponent } from './virtualgames/virtualgames.component';
import { OnlinegamesComponent } from './onlinegames/onlinegames.component';
import { CasinogameComponent } from './casinogame/casinogame.component';
import { LinksolutionsComponent } from './linksolutions/linksolutions.component';
import { VltComponent } from './vlt/vlt.component';
import { CmsComponent } from './cms/cms.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    YgtPageDirective,
    FooterComponent,
    SportsbettingComponent,
    LotteryComponent,
    VirtualgamesComponent,
    OnlinegamesComponent,
    CasinogameComponent,
    LinksolutionsComponent,
    VltComponent,
    CmsComponent,
    ContactComponent,

    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
