import { Directive, HostListener, Renderer2, ElementRef, ViewChildren, QueryList, Input, EventEmitter, Output } from '@angular/core';
import { CommenService } from '../commen.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
@Directive({
  selector: '[appYgtPage]'
})
export class YgtPageDirective {

  flage: boolean = false;
  startX: any;
  startY: any;
  moveX: any;
  moveY: any;
  endX: any;
  endY: any;
  innerWidth: number;
  innerHeight: number;
  drageGap: number = 100;
  drageGapLeft: number = 100;
  touchmoveListenFunc: Function = null;
  touchendListenFunc: Function = null;
  touchcancelListenFunc: Function = null;
  pageLength: number = 2;
  activePageNo: number = 0;
  timeStamp: any = null;
  timeStampMb: any = null;
  _flagTopLeft = null;
  _FirstLoad = true;
  _setTimer1: any = null
  _setTimer2: any = null

  // child slider var

  activePage: any = null;
  ChildActivePageNo: number = 0;
  ChildPageLength: number = 0;
  childeShowItem = 2;
  childeShowItem2 = 2;


  @Input() _stopScroll: any;
  // @Input() _ActivePage: number;

  @Output() _ActivePage: EventEmitter<any> = new EventEmitter();
  @Output() _ActivePageChild: EventEmitter<any> = new EventEmitter();
  // @Output() _pageFun = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private elmRef: ElementRef,
    private commenService: CommenService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.commenService._stopScrollActive.subscribe(message => {
      this._stopScroll = message;
    });




  }




  // public emmitEvent(){
  //   alert()
  //   this._pageFun.emit();
  // }

  // --------------------------------------------------
  // --------------------------------------------------
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  onStart(event) {

    if (window.location.pathname == '/registration' || window.location.pathname == '/edit')
      return;

    if (this._stopScroll)
      return;

    if (this._setTimer1)
      this.autoPlayClear()

    if (event.cancelable)
      event.preventDefault();
    this.timeStampMb = event.timeStamp;
    this.flage = true;
    //console.log('start');
    this.startY = event.touches ? event.touches[0].clientY : event.clientY;
    this.startX = event.touches ? event.touches[0].clientX : event.clientX;
    if (event.touches) {                // only for touch
      this.removePreviousEvent();
      this.touchmoveListenFunc = this.renderer.listen(event.target, 'touchmove', (e) => {
        this.onMove(e);
      });
      this.touchendListenFunc = this.renderer.listen(event.target, 'touchend', (e) => {

        this.removePreviousEvent();
        this.onEnd(e);
      });
      this.touchcancelListenFunc = this.renderer.listen(event.target, 'touchcancel', (e) => {
        this.removePreviousEvent();
        this.onEnd(e);
      });
    }
  }
  // --------------------------------------------------
  // --------------------------------------------------
  //@HostListener('document:touchmove', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  onMove(event) {


    if (window.location.pathname == '/registration' || window.location.pathname == '/edit')
      return;


    if (this._stopScroll)
      return;

    if (event.cancelable)
      event.preventDefault();
    if (this.flage) {
     // console.log('move');
      this.moveY = event.touches ? event.touches[0].clientY : event.clientY;
      this.moveX = event.touches ? event.touches[0].clientX : event.clientX;
      this.endY = this.moveY;
      this.endX = this.moveX;
      this.setPagination('move');
    }
  }
  // --------------------------------------------------
  // --------------------------------------------------
  //@HostListener('document:touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  onEnd(event) {


    if (window.location.pathname == '/registration' || window.location.pathname == '/edit')
      return;

    //event.preventDefault();
    if (this._stopScroll)
      return;
    this.flage = false;
    this.endY = this.moveY;
    this.endX = this.moveX;
    if (this.moveY == null) {
      if (this._flagTopLeft)
        this.translate3d('all', 500);
      else if (!this._flagTopLeft && this.activePage)
        this.translate3dLR('all', 500);
      return;
    }


    this.setPagination('end');
    this.startX = this.moveX;
    this.startY = this.moveY;

    this.moveY = null;
    this.moveX = null;

  }
  // --------------------------------------------------
  // --------------------------------------------------
  translate3d(ev, time) {
    let val = -this.innerHeight * this.activePageNo;
    let style = `transform: translate3d(0px, ${val}px, 0px);transition: ${ev} ${time}ms ease 0s;`;
    this.setAttribute(this.elmRef.nativeElement, 'style', style);
  }
  // --------------------------------------------------
  // --------------------------------------------------
  @HostListener('window:resize', [])
  onResize() {
    //  if (this._stopScroll)
    //  return;


    setTimeout(() => {

      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.drageGap = this.innerHeight / 6;
      this.drageGapLeft = this.innerWidth / 6;
      // this.innerWidth = screen.width;
      //   this.innerHeight = screen.height;
      //   this.drageGap = this.innerHeight / 6;
      //     this.drageGapLeft = this.innerWidth / 6;




      this.ActiveClass();
      this.setHeight();
      this.translate3d('none', 0);
      this.setActivePage();
      // if(this.activePage)
      // this.checkScrollActive(this.activePage.children[0]);

    }, 500);
  }
  // --------------------------------------------------
  // --------------------------------------------------
  ngAfterViewInit() {
    this.pageLength = this.elmRef.nativeElement.children.length - 1;
    this.onResize();
    this.clickEventFun();
   setTimeout(() => {
    this.autoPlay();
   }, 4000);

  }
  // --------------------------------------------------
  // --------------------------------------------------
  setHeight() {
    let h = this.innerHeight + "px";
    // this.elmRef.nativeElement.childNodes.forEach(hello => {
    //   this.setStyle(hello, 'height', h);
    // });

    let _ch = this.elmRef.nativeElement.children;
    let _chLength = this.elmRef.nativeElement.children.length;

    for (let i = 0; i < _chLength; i++) {

      this.setStyle(_ch[i], 'height', h);
    }



  }
  // --------------------------------------------------
  // --------------------------------------------------
  setPagination(e) {
    switch (e) {
      case 'click':
        break;
      case 'move':
        this.PageDrag(this.checkDirection(true));
        break;
      case 'end':
        this.setPage(1, this.checkDirection(false));
        break;
    }
  }
  // --------------------------------------------------
  // -------------------------------------------------
  checkDirection(move: boolean) {
    let R_L_Deffrent = this.startX - this.endX;
    let T_B_Deffrent = this.startY - this.endY;
    let R_L = Math.abs(R_L_Deffrent);
    let T_B = Math.abs(T_B_Deffrent);
    //console.log(T_B);
    let R_L$T_B = T_B > R_L;
    if (R_L$T_B) {
      this._flagTopLeft = true;

      if (T_B_Deffrent > 0 && T_B_Deffrent > this.drageGap || move)
        return 'top'
      if (T_B_Deffrent < 0 && T_B_Deffrent < -(this.drageGap) || move)
        return 'bottom'
    } else {
      this._flagTopLeft = false;
      if (R_L_Deffrent > 0 && R_L_Deffrent > this.drageGapLeft || move)
        return 'left'
      if (R_L_Deffrent < 0 && R_L_Deffrent < -(this.drageGapLeft) || move)
        return 'right'
    }
  }
  // --------------------------------------------------
  // --------------------------------------------------
  setPage(PageNo: number, PagePosition: string) {


    debugger
    if (this.pageLength >= this.activePageNo && PagePosition === 'top' && this.pageLength != this.activePageNo) {
      if (this._flagTopLeft) {
        this.activePageNo++;
        this.translate3d('all', 1000);
        this.setActivePage();
      }
    } else if (this.activePageNo <= this.pageLength && this.activePageNo > 0 && PagePosition == 'bottom') {
      if (this._flagTopLeft) {
        this.activePageNo--;
        this.translate3d('all', 1000);
        this.setActivePage();
      }
    } else if (this.ChildPageLength >= this.ChildActivePageNo && PagePosition === 'left' && this.ChildPageLength != this.ChildActivePageNo) {
      if (!this._flagTopLeft) {
        this.ChildActivePageNo++;
        this.translate3dLR('all', 1000);
        this._ActivePageChild.emit(this.ChildActivePageNo);
        this.checkScrollActive(this.activePage.children[0]);
      }
    } else if (this.ChildActivePageNo <= this.ChildPageLength && this.ChildActivePageNo > 0 && PagePosition == 'right') {
      if (!this._flagTopLeft) {
        this.ChildActivePageNo--;
        this.translate3dLR('all', 1000);
        this._ActivePageChild.emit(this.ChildActivePageNo);
        this.checkScrollActive(this.activePage.children[0]);
      }
    }
    else {
      if (this._flagTopLeft)
        this.translate3d('all', 500);
      else
        this.translate3dLR('all', 500);
    }



  }
  // --------------------------------------------------
  // --------------------------------------------------
  setActivePage() {
    debugger;
    let h = this.innerHeight + "px";
    // this._ActivePage=this.activePageNo;
    this._ActivePage.emit(this.activePageNo);
    this.commenService.changePage(this.activePageNo);
    let childrens = this.elmRef.nativeElement.children;
    let childrensLength = this.elmRef.nativeElement.children.length;
    this._stopScroll = false;
    for (let i = 0; i < childrensLength; i++) {

      this.removeClass(childrens[i], 'active');
      if (i == this.activePageNo || this._FirstLoad) {

        this.addClass(childrens[i], 'active');

        let ch = childrens[i].children;
        let _chLength = childrens[i].children.length;
        let bodyEl = document.querySelector('body');
        for (let j = 0; j < _chLength; j++) {

          if (ch[j].classList.contains('pageSlider')) {
            if (bodyEl.classList.contains('NopageSlider'))
              bodyEl.classList.remove('NopageSlider')

            if (ch[j].classList.contains('siggleItem'))
              this.childeShowItem = 1;
            else
              this.childeShowItem = this.childeShowItem2;
            this._FirstLoad = false;
            if (this.activePage)
              this.destroyFun(this.activePage.children[0]);

            this.activePage = ch[j];

            this.checkScrollActive(this.activePage.children[0]);
            this.ChildActivePageNo = 0;
            this._ActivePageChild.emit(0);
            this.setStyle(ch[j], 'width', this.innerWidth + "px");
            let _ch = ch[j].children[0].children[0];
            let _chCH = ch[j].children[0].children[0].children;
            let chLength = _ch.children.length
            if (chLength == 0)
              break;

            this.ChildPageLength = chLength - 1;
            let w = (this.innerWidth / this.childeShowItem);
            //   this.setStyle(_ch, 'flex', '0 0 ' + w * chLength + "px");
            this.setStyle(_ch, 'width', w * chLength + "px");
            for (let k = 0; k < chLength; k++) {
              this.setStyle(_chCH[k], 'width', w + "px");
            }
          } else {
            bodyEl.classList.add('NopageSlider')
          }
        }


      }
    }

    // this.elmRef.nativeElement.childNodes.forEach((obj, index) => {
    //   this.removeClass(obj, 'active');
    //   if (index == this.activePageNo) {
    //     this.addClass(obj, 'active');

    //     obj.childNodes.forEach((objCh, index) => {
    //       if (objCh.classList.contains('pageSlider')) {
    //         this.activePage = objCh;
    //         this.ChildActivePageNo = 0;
    //         this.setStyle(objCh, 'width', this.innerWidth + "px");
    //         let ch = objCh.children[0].children[0];
    //         let chLength = ch.children.length
    //         this.ChildPageLength = chLength - 1;
    //         let w = (this.innerWidth / this.childeShowItem);
    //         this.setStyle(ch, 'width', w * chLength + "px");
    //         ch.childNodes.forEach((a, index) => {
    //           this.setStyle(a, 'width', w + "px");
    //         });

    //       }

    //     });
    //   }

    // });











    this.ActiveClass();

    this.subItemButFun();
  }


  ActiveClass() {
    debugger
    let bodyEl = document.querySelector('body');

    if (this.innerWidth > this.innerHeight)
      this.addClass(bodyEl, 'landscape_ygt');
    else
      this.removeClass(bodyEl, 'landscape_ygt');





    if (this.innerWidth < 1280) {
      this.addClass(bodyEl, 'activeSlidMB');
      this.childeShowItem2 = 1;
    }
    else {
      this.removeClass(bodyEl, 'activeSlidMB');
      this.childeShowItem2 = 2;
    }

    if (this.activePageNo != 0 || this.innerWidth < 1280)
      this.addClass(bodyEl, 'activeSlid');
    else
      this.removeClass(bodyEl, 'activeSlid');


  }



  // --------------------------------------------------
  // --------------------------------------------------
  PageDrag(PagePosition: string) {
    switch (PagePosition) {
      case 'top':
        this.pageDragTopBottom(PagePosition);
        break;
      case 'bottom':
        this.pageDragTopBottom(PagePosition);

      case 'left':
        this.pageDragLeftRight(PagePosition);

      case 'right':
        this.pageDragLeftRight(PagePosition);
        break;
    }
  }
  // --------------------------------------------------
  // --------------------------------------------------
  pageDragTopBottom(e) {
    if (this._flagTopLeft) {
      let T_B_Deffrent = this.startY - this.endY;
      let tVal = (-this.innerHeight * this.activePageNo) - T_B_Deffrent;
      let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: none 0ms ease 0s;`;
      this.setAttribute(this.elmRef.nativeElement, 'style', val);
    }

  }



  // --------------------------------------------------
  // --------------------------------------------------
  @HostListener("window:wheel", ['$event']) onWindowScroll(event) {
 
    if (this._stopScroll)

      return;

    this.autoPlayClear()

    if ((this.timeStamp === null || this.timeStamp < event.timeStamp) && this.innerWidth >= 1024) {
      this.timeStamp = event.timeStamp + 500;
      let delta = event.wheelDelta;
      this._flagTopLeft = true;
      if (delta > 0)
        this.setPage(1, 'bottom');
      else
        this.setPage(1, 'top');
    }
  }
  // --------------------------------------------------
  // --------------------------------------------------
  addClass(el: object, className: string) {
    this.renderer.addClass(el, className);
  }
  removeClass(el: object, className: string) {
    this.renderer.removeClass(el, className);
  }
  setAttribute(el: object, Name: string, Value: string) {
    this.renderer.setAttribute(el, Name, Value);
  }
  setStyle(el: object, Name: string, Value: string) {
    this.renderer.setStyle(el, Name, Value);
  }
  // --------------------------------------------------
  // --------------------------------------------------
  removePreviousEvent() {
    if (this.touchmoveListenFunc !== null)
      this.touchmoveListenFunc();             // remove previous listener
    if (this.touchendListenFunc !== null)
      this.touchendListenFunc();              // remove previous listener
    if (this.touchcancelListenFunc !== null)
      this.touchcancelListenFunc();           // remove previous listener
    this.touchmoveListenFunc = null;
    this.touchendListenFunc = null;
    this.touchcancelListenFunc = null;
  }




  // child slider



  pageDragLeftRight(e) {
    debugger;
    if (!this._flagTopLeft && this.activePage) {
      let T_B_Deffrent = this.startX - this.endX;
      let tVal;
      if (T_B_Deffrent)
        tVal = (-(this.innerWidth / this.childeShowItem) * this.ChildActivePageNo) - T_B_Deffrent;
      else
        tVal = (-(this.innerWidth / this.childeShowItem) * this.ChildActivePageNo);

      let val = `transform: translate3d( ${tVal}px,0px,0px);transition: none 0ms ease 0s;`;
      this.setAttribute(this.activePage.children[0], 'style', val);
    }
  }

  translate3dLR(ev, time) {

    if (!this._flagTopLeft && this.activePage) {
      let val = (-(this.innerWidth / this.childeShowItem) * this.ChildActivePageNo);
      let style = `transform: translate3d(${val}px,0px,  0px);transition: ${ev} ${time}ms ease 0s;`;
      this.setAttribute(this.activePage.children[0], 'style', style);

    }
  }



  checkScrollActive(e) {

    setTimeout(() => {
      let ch = e.children[0].children[this.ChildActivePageNo];
      if (ch && this.innerHeight < 768) {
        if (ch.scrollHeight > ch.clientHeight && ch.classList.contains('scrollvisible')) {

          this.setStyle(ch, 'overfolw', 'auto')


          ch.addEventListener("scroll", (e) => {
            if (e.cancelable)
              e.preventDefault();
            if (ch.scrollTop >= (ch.scrollHeight - ch.offsetHeight) - 1)
              this._stopScroll = false;
            else if (ch.scrollTop == 0)
              this._stopScroll = false;



          });



        }
        else
          this._stopScroll = false;



      }

    }, 1100);



  }


  clickEventFun() {

    let linkBut: any = document.querySelectorAll(".linkBut");
    let hLength = linkBut.length;
    for (let ix = 0; ix < hLength; ix++) {
      linkBut[ix].onclick = () => {
        this.activePageNo = linkBut[ix].id;
        let tVal = (-this.innerHeight * this.activePageNo);
        let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: all 500ms ease 0s;`;
        this.setAttribute(this.elmRef.nativeElement, 'style', val);
        this.setActivePage();
      }
    }

    let but: any = document.querySelector(".productListButDiractive");
    let butLength = but.children.length;
    for (let i = 0; i < butLength; i++) {
      if (but.children[i].tagName == 'DIV')
        but.children[i].onclick = () => {
          this.activePageNo = i + 1;
          let tVal = (-this.innerHeight * this.activePageNo);
          let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: all 500ms ease 0s;`;
          this.setAttribute(this.elmRef.nativeElement, 'style', val);
          this.setActivePage();
        }
    }


    let but2: any = document.querySelectorAll(".viewMoreButDiractive");
    let butLength2 = but2.length;
    for (let i = 0; i < butLength2; i++) {
      but2[i].onclick = () => {
        this.activePageNo = but2[i].id;
        let tVal = (-this.innerHeight * this.activePageNo);
        let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: all 500ms ease 0s;`;
        this.setAttribute(this.elmRef.nativeElement, 'style', val);
        this.setActivePage();
      }
    }






    let butNextPrev: any = document.querySelector(".nextPrevButDiractive");



    for (let i = 0; i < 2; i++) {

      if (i == 0)
        this.setStyle(butNextPrev.children[i], 'display', 'none')

      butNextPrev.children[i].onclick = () => {
        this._flagTopLeft = false;

        this.nextButActive(i);

      }


    }


    // let but1: any = document.querySelector(".hedaerMenuDiractive");
    // let butLength1 = but1.children.length;
    // for (let q = 0; q < butLength1; q++) {
    //   if (but1.children[q].tagName == 'LI')
    //     but1.children[q].onclick = () => {
    //       debugger;
    //       this.activePageNo = q + 1;
    //       let tVal = (-this.innerHeight * this.activePageNo);
    //       let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: all 500ms ease 0s;`;
    //       this.setAttribute(this.elmRef.nativeElement, 'style', val);
    //       this.setActivePage();
    //     }
    // }


  }


  nextButActive(e) {

    let butNextPrev: any = document.querySelector(".nextPrevButDiractive");
    if (e === 0) {
      this.setPage(1, 'right');
      if (this.ChildActivePageNo != this.ChildPageLength)
        this.setStyle(butNextPrev.children[1], 'display', 'inline-block');

      if (this.ChildActivePageNo == 0)
        this.setStyle(butNextPrev.children[0], 'display', 'none')

    } else {
      this.setPage(1, 'left');
      if (this.ChildActivePageNo != 0)
        this.setStyle(butNextPrev.children[0], 'display', 'inline-block')
      if (this.ChildActivePageNo === this.ChildPageLength)
        this.setStyle(butNextPrev.children[1], 'display', 'none');

    }

  }

  //   scrollActive(e) {

  //     let scrollDiractive: any = document.querySelectorAll(".scrollDiractive");
  //     for (let i = 0; i < scrollDiractive.length; i++) {

  //       scrollDiractive[i].onscroll = () => {
  //  alert()

  //       }
  //     }

  //   }


  subItemButFun() {
    debugger;


    let subItemBut: any = document.querySelectorAll(".productDetailsPages.active .subitemBut");
    debugger;
    if (subItemBut.length != 0) {
      for (let i = 0; i < subItemBut.length; i++) {
        subItemBut[i].onclick = () => {
          this._flagTopLeft = false;
          this.ChildActivePageNo = i;
          this.translate3dLR('all', 1000);
          this._ActivePageChild.emit(this.ChildActivePageNo);
        }
      }

    }

  }


  destroyFun(e) {
    let val = `transform: translate3d(0px, 0px, 0px);transition: all 0ms ease 0s;`;
    this.setAttribute(e, 'style', val);

  }



  autoPlay() {

    let id = null;
    this.route.queryParams.subscribe(params => {
      debugger;
      id = params['id'];
      //console.log(id)
      if (id) {
        this.activePageNo = id;
        let tVal = (-this.innerHeight * this.activePageNo);
        let val = `transform: translate3d(0px, ${tVal}px, 0px);transition: all 500ms ease 0s;`;
        this.setAttribute(this.elmRef.nativeElement, 'style', val);
        this.setActivePage();   
        this.autoPlayClear();    
      }else{
        this.autoPlayFun();

      }
    });
 
  }

  autoPlayClear() {

    if (this._setTimer1) {
      clearTimeout(this._setTimer1);
      clearTimeout(this._setTimer2);
      this._setTimer1 = null;
      this._setTimer2 = null;

    }
  }


  autoPlayFun() {


    let delay = 5000;
    let Pgno = 0;
    let CPgno = 0;
    this._setTimer1 = setTimeout(() => {
      Pgno++;
      tbFun();
      loopFun();
    }, delay);
    let loopFun = () => {
      this._setTimer2 = setTimeout(() => {
        if (CPgno < this.ChildPageLength) {
          CPgno++;
          rlFun();
          loopFun();
        } else {
          CPgno = 0;
          if (Pgno < this.pageLength) {
            Pgno++
            tbFun();
            loopFun();
          } else {
            Pgno = 0;
            this.activePageNo = 0;
            let val = `transform: translate3d(0px, 0px, 0px);transition: all 500ms ease 0s;`;
            this.setAttribute(this.elmRef.nativeElement, 'style', val);
            this.setActivePage();
            this.autoPlayFun();
          }
        }

      }, delay)
    }


    let tbFun = () => {
      this._flagTopLeft = true;
      this.setPage(1, 'top');
    }


    let rlFun = () => {
      this._flagTopLeft = false;
      this.setPage(1, 'left');
      // if()
      // this.autoPlayFun();
    }


  }







}












