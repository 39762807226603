import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: '.app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  error:any={
    mg:null,
    ty:'success'
  }

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {}
  ngOnInit() {
    this.form = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Mobile: ['', Validators.required],
      Subject: ['', [Validators.maxLength(150)]],
      Message: ['', [Validators.maxLength(150)]]
    });
  }
  get f() {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;
    if ((this.form.controls.Email.status == "INVALID" && this.form.controls.Mobile.status == "INVALID") || this.form.controls.Name.status == "INVALID") {
      return;
    }
    this.http.post("http://demo.ysecit.in:8082/YGTWebService/api/ContactUs/SendEmail",
      this.form.value,{
        headers: new HttpHeaders().set("Content-Type", "application/json")
      }
    ).subscribe((response: any) => {  
      this.form.reset();
      this.error['mg'] = 'success';
      this.error['ty'] = 'success';
      setTimeout(() => {
        this.error['mg'] = null;
      }, 5000);
    }, (errorResponse: any) => {    
      this.error['mg'] = 'error';
      this.error['ty'] = 'danger';
      setTimeout(() => {
        this.error['mg'] = null;
      }, 5000);
    });
  }











}

