import { Component, OnInit } from '@angular/core';

@Component({
  selector: '.app-lottery',
  templateUrl: './lottery.component.html',
  styleUrls: ['./lottery.component.scss']
})
export class LotteryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
