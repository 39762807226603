import { Component } from '@angular/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'YGT';
  loaded:boolean= true;
  ngOnInit() {
  //   document.addEventListener('DOMContentLoaded', ()=> { 



  // }, false);

  setTimeout(() => {
    this.loaded= false;
  }, 3600);
  
  }

}
