import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommenService } from '../commen.service';
@Component({
  selector: '.app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  _menuActive: boolean = false;
  _menuActivePage: any = 0;
  _contactActive: boolean = false;
  _inFoActive: any = false;
  productMenu: any = [

    {
      'name': 'Sports Betting',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/sport-bitting.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/banner3.png',
      'iconClass': '-Sports',
      'id': 1
    }, {
      'name': 'Lottery Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lottery.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/lotteryBg.jpg',
      'iconClass': '-lottery',
      'id': 2
    }, {
      'name': 'Virtual Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-games.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/VirtualGamesBg.jpg',
      'iconClass': '-virtual',
      'id': 3
    },

    {
      'name': 'Online Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/online-game.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/onlineGamesBg.jpg',
      'iconClass': '-online',
      'id': 4
    },
    {
      'name': 'CMS',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/CMS.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/cmsBg.jpg',
      'iconClass': '-cms',
      'id': 5
    },
    {
      'name': 'VLT',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/VLT.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass': '-vlt',
      'id': 6
    }, {
      'name': 'Link Solutions',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Link-Solutions.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass': '-link-solution',
      'id': 7
    },
    {
      'name': 'Casino Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/casino-games.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg': '//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass': '-casino',
      'id': 8
    },

  ]


  constructor(private commenService: CommenService) { }

  ngOnInit() {

    this.commenService._activePageNo.subscribe(message => {
      this._menuActivePage = message;
    });

    this.commenService._inFoActiveFlag.subscribe(message => {   
      this._inFoActive = message;
    });





  }
  menuActiveFun() {

    this._menuActive = !this._menuActive;
  }

  contactActiveFun(e) {
    this._contactActive = e;
    this.commenService.changestopScroll(this._contactActive);
  }

}
