import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommenService } from '../commen.service';

@Component({
  selector: '.app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

_stopScroll:boolean = false;
_ActivePage:number = 0;
_ActivePageChild:number = 0;
InFoPageindex:any = null;

bannerData: any = [
  {
    'name': 'WHY YGT?',
    'title': 'YGT offers a flexible dynamic customized gaming platform for multiple channels (games anytime, anywhere on any device) that consist a bundle of various games which can be managed through a comprehensive back office system.',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/onlineGamesBg.jpg',
    'id':null
  },
  {
    'name': 'Sports Betting',
    'title': 'YGT offers “Sports Betting” as a comprehensive package with top to bottom solutions that supports for all the platforms. We collect data from various bookmakers of live & pre-match to integrate for providing odds. We are also partner of some of the top-notch market leaders for data such as BETRADER and LSPORTS to offer odds for clients who intend to provide SPORTS BETTING globally. ',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
    'id':1
  },   {
    'name': 'Lottery Games',
    'title': 'YGT offers a comprehensive LOTTERY management solution for operators involved in physical ball lottery studios with multiple number combination (2/3/4….. n number).',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/lotteryBg.jpg',
    'id':2
  },   {
    'name': 'Virtual Games', 
    'title': 'YGT offers with a vast variety of products for Virtual Games, consisting of all Sports, Racing and table based games. ',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/VirtualGamesBg.jpg',
    'id':3
  },  
{
    'name': 'Online Games',   
    'title': 'Over the years YGT rapidly expanding into the online gaming market. YGT online gaming platform games are in HTML5 format that allows to be played anytime, anywhere and on any device. ',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/onlineGamesBg.jpg',
    'id':4
  },
  
  {
    'name': 'CMS',   
    'title': 'YGT offers a complete end-to-end solution for “Casino Management System” (CMS). CMS plays a vital role in efficient regulatory and compliance reporting by generating and managing reporting system of day-to-day activities involved for a casino operations and transactions. We also provide business analytics through integrated systems for fast data mining with automation capabilities include with monitoring solutions through back office payment and accounting systems.',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/cmsBg.jpg',
    'id':5
  },
  {
    'name': 'VLT', 
    'title': 'YGT offers a server based Multi Game MG Video Lottery Terminal VLT system. The VLT system is designed for multiple operations scenario, including specialized custom workflows and wide area online gaming processes.  ',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/VLTbg.jpg',
    'id':6
  },    {
    'name': 'Link Solutions',    
    'title': 'YGT offers a vast variety of products for Link Solutions that increase both attraction and winning probabilities.',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/linkssoluationBg.jpg',
    'id':7
  },
  {
    'name': 'Casino Games',    
    'title': 'YGT offers a special unique casino live game solution which is played online and offline simultaneous. This solution also known as Casino Live Gaming provide the possibility to stream the casino floor table live for players online to play the game.  ',
    'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/casinoGamesBg.jpg',
    'id':8
  }

]

  productMenu: any = [

    {
      'name': 'Sports Betting',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/sport-bitting.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/banner3.png',
      'iconClass':'-Sports'
    },   {
      'name': 'Lottery Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lottery.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/lotteryBg.jpg',
      'iconClass':'-lottery'
    },   {
      'name': 'Virtual Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-games.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/VirtualGamesBg.jpg',
      'iconClass':'-virtual'
    },  
 
    {
      'name': 'Online Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/online-game.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/onlineGamesBg.jpg',
      'iconClass':'-online'
    },
    {
      'name': 'CMS',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/CMS.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/cmsBg.jpg',
      'iconClass':'-cms'
    },
    {
      'name': 'VLT',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/VLT.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass':'-vlt'
    },    {
      'name': 'Link Solutions',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Link-Solutions.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass':'-link-solution'
    },
    {
      'name': 'Casino Games',
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/casino-games.png",
      'title': 'Find out everything you need to know about what Virtual Sports are and how ..',
      'imgBg':'//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg',
      'iconClass':'-casino'
    },

  ]



  productDetails: any = [
    
    {
    'name': "Sports Betting",
    'img': "//cdn2-5e15.kxcdn.com/YGT/assets/sportsBettingBG.jpg",
    'dec': "YGT offers “Sports Betting” as a comprehensive package with top to bottom solutions that supports for all the platforms. We collect data from various bookmakers of live & pre-match to integrate for providing odds. We are also partner of some of the top-notch market leaders for data such as BETRADER and LSPORTS to offer odds for clients who intend to provide SPORTS BETTING globally. ",
       
    'item': [
      {
        'name': "Sport  Betting",
        'dec': "SPORTS BETTING or PRE-MATCH ODDS Data, We provide everything you need to create and manage your own sportsbook – from event creation, odds generation, market monitoring and result confirmation",
        'img': "./../assets/sport-bitting_screen.png",
        'item': [

        ]
      },
      {
        'name': "Live Betting ",
        'dec': "LIVE BETTING or LIVE MATCH ODDS is the most popular in-play trading service in the market and offers a broad variety of live sports betting with market types.",
        'img': "//cdn2-5e15.kxcdn.com/YGT/assets/liveBettingScreen.png",
        'item': [
           ]
      },
      {
        'name': "Statistics",
        'dec': "SPORTS STATISTICS DATA, We provide historical data of various sports, events and tournaments across the globe to serve better odds.",
        'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Statistics_Screen.png",
        'item': [
         
        ]
      },
    ]
  
},
{
  'name': "Lottery Games",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lotteryBg.jpg",
  'dec': "YGT offers a comprehensive LOTTERY management solution for operators involved in physical ball lottery studios with multiple number combination (2/3/4….. n number). ",
     
  'item': [
    {
      'name': "Daily Games",
      'dec': "YGT offers Daily Game product as a complete package for lottery system, where game consists of N number of balls those are represented by a unique number and each number has unique multiplier which is fully customizable.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/LotteryDailyGames.png",
      'item': [ ]
    },
    {
      'name': "Lotto",
      'dec': "YGT’s LOTTO JACKPOT solution purely used to organize and manage lottery systems, where lotto jackpot solution come with customization of elements for numbered ball and letter ball lottery with jackpot price. We provide a flexible solution to manage your own type of lottery and jackpot price. In addition, Lotto Jackpot draws can be managed and customize for any date and time range.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lotteryLotto.png",
      'item': [  ]
    },
    {
      'name': "Scratch Card",
      'dec': "YGT offer scratch lottery management solution for operators who are involved in scratch lottery. In additional we also provide features such as Logistics, Inventory, Ticket Tracking & Validation, Sales & Distribution.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lotteryScratchCard.png",
      'item': [ ]
    },
  ]

}, 
   {
  'name': "Virtual Games",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/VirtualGamesBg.jpg",
  'dec': "YGT offers with a vast variety of products for Virtual Games, consisting of all Sports, Racing and table based games. ",
     
  'item': [
    {
      'name': "Virtual Blackjack",
      'dec': "YGT’s Offer “Virtual Blackjack” table-based game as a comprehensive product that is available for online & land-base. Game comprising of multiple options for both platforms. ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-gamesBlackjack.png",
      'item': []
    },
    {
      'name': "Virtual Daily Game",
      'dec': "Virtual Daily Game is similar to lottery game. Designed in way to match and look same as lottery game where game is about drawing numbers at random from a bowl; in general contains 36 numbers and those can be customized.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-gamesDailyGamek.png",
      'item': [
      ]
    },
    {
      'name': "Virtual Roulette",
      'dec': "YGT offers “Virtual Roulette” game as a whole product with end-to-end solution for Land-based & online platforms with dealer. Game, which played and controlled through server with ease in customization to configure.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-gamesVirtualRoulette.png",
      'item': [
      ]
    },
    {
      'name': "Virtual Sicbo",
      'dec': "YGT provides “Virtual Sicbo” game as a comprehensive product that consists of dealer and dynamic features controlled through server. Game can be customize & configure with ease according to requirements. Listed below are some key features.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-gamesSicBo.png",
      'item': [
      ]
    },
    {
      'name': "Virtual Sports",
      'dec': "We provide all sports based games with end-to-end solutions with all type of events & tournaments variations.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-gamesVirtual_Sports.png",
      'item': [
      ]
    },
  ]

},
   {
  'name': "Online Games",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/onlineGamesBg.jpg",
  'dec': "Over the years YGT rapidly expanding into the online gaming market. YGT online gaming platform games are in HTML5 format that allows to be played anytime, anywhere and on any device. ",
     
  'item': [
    {
      'name': "Slot Games",
      'dec': "YGT provides a wide variety of “Slot Games” available online for player. Slots games with different themes – Fruits, Theme	",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Slot-Games.png",
      'item': []
    },
    {
      'name': "Table games",
      'dec': "YGT offers Blackjack card based game with all standard features where users has freedom to choose single or multi hand feature to play against dealer’s hand with user friendly UI.",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Table-Games.png",
      'item': [
      ]
    },
    {
      'name': "Card games ",
      'dec': "YGT provides “Roulette” game as one of the comprehensive product for table-based game inclusive of all standard & custom features of game. ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/Card-Games.png",
      'item': [
      ]
    }, {
      'name': "Scratch card games ",
      'dec': "YGT provides “Scratch Card” game different variations in design and payout combinations. Game includes all standard & custom features and payouts. ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/lotteryScratchCard.png",
      'item': [
      ]
    }
  ]

}
,
   {
  'name': "CMS",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/cmsBg.jpg",
  'dec': "YGT offers a complete end-to-end solution for “Casino Management System” (CMS). CMS plays a vital role in efficient regulatory and compliance reporting by generating and managing reporting system of day-to-day activities involved for a casino operations and transactions. We also provide business analytics through integrated systems for fast data mining with automation capabilities include with monitoring solutions through back office payment and accounting systems.",
  "imgIcon":"//cdn2-5e15.kxcdn.com/YGT/assets/cmsIcon.png",
  'item': [{


    'name': "CMS",
    'dec': "YGT offers a complete end-to-end solution for “Casino Management System” (CMS). CMS plays a vital role in efficient regulatory and compliance reporting by generating and managing reporting system of day-to-day activities involved for a casino operations and transactions. We also provide business analytics through integrated systems for fast data mining with automation capabilities include with monitoring solutions through back office payment and accounting systems. ",
    'img': "//cdn2-5e15.kxcdn.com/YGT/assets/cmsIcon.png",
    'item': []
  }

    ]

},
   {
  'name': "VLT",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/VLTbg.jpg",
  'dec': "YGT offers a server based Multi Game MG Video Lottery Terminal VLT system. The VLT system is designed for multiple operations scenario, including specialized custom workflows and wide area online gaming processes.  ",
     "imgIcon":"//cdn2-5e15.kxcdn.com/YGT/assets/VLT_icon.png",
  'item': [{


    'name': "VLT",
    'dec': "YGT offers a server based Multi Game MG Video Lottery Terminal VLT system. The VLT system is designed for multiple operations scenario, including specialized custom workflows and wide area online gaming processes.  ",
    'img': "//cdn2-5e15.kxcdn.com/YGT/assets/VLT_icon.png",
    'item': []
  }

    ]

},
   {
  'name': "Links Solution ",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/linkssoluationBg.jpg",
  'dec': "YGT offers a vast variety of products for Link Solutions that increase both attraction and winning probabilities.",
     
  'item': [
    {
      'name': "Mega Star Jackpot",
      'dec': "Mega Star Jackpot consisting 4 levels of jackpot gems with a star shape (Diamond, Ruby, Emerald and Sapphire) where all online interactive games are liked to this Jackpot also known as “Mystery Jackpot”.  ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/megaStarJackpot.png",
      'item': []
    },
    {
      'name': "Virtual Jackpot",
      'dec': "YGT has brought all virtual games in to one section and offers a Virtual Linked Jackpot that consists 3 levels of jackpot.  ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/virtual-jackpot.png",
      'item': [
      ]
    },{
      'name': "Dice Link",
      'dec': "The Dice Link also known as Progressive Jackpot consist 6 levels of jackpots Linked to a group of Slot Games and VLT Terminals.  ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/dicelink.png",
      'item': [
      ]
    },{
      'name': "Super Lucky Link",
      'dec': "It’s a 4 level Jackpot (progressive jackpot) Linked to a group of Slot Games and VLT Terminals. ",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/superLuckyLink.png",
      'item': [
      ]
    },{
      'name': "Coral Linked Jackpot",
      'dec': "The coral linked is a 3-level progressive jackpot which is linked with a group of games",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/coral-link-jackpot.png",
      'item': [
      ]
    },
  ]

},
   {
  'name': "Casino Games ",
  'img': "//cdn2-5e15.kxcdn.com/YGT/assets/casinoGamesBg.jpg",
  'dec': "YGT offers a special unique casino live game solution which is played online and offline simultaneous. This solution also known as Casino Live Gaming provide the possibility to stream the casino floor table live for players online to play the game.  ",

  'item': [
    {
      'name': "Live Roulette",
      'dec': "Live Roulette game is a casino floor live table played online as well in the casino with real dealer who will be acting according to time and where players are allowed to place bets within specified time duration",
      'img': "//cdn2-5e15.kxcdn.com/YGT/assets/LiveRoulette.png",
      'item': []
    }
 
  ]

}

  ]

  productButMb:boolean=false;
  constructor(private commenService: CommenService,) { }

  ngOnInit() {

  
  }



  productButMbFun(e){
    debugger
   e.preventDefault();
this.productButMb = !this.productButMb;

  }


  productItemFun(e){
    debugger
   e.preventDefault();
alert()

  }


  infoFun(e,index){
    e.preventDefault();
   this._stopScroll  = true;
   this.InFoPageindex = index;
   this.commenService.changes_inFoActive(true);
  }
  backInfoPageFun(){
    debugger;
    this._stopScroll  = false;
    this.InFoPageindex = null;
    this.commenService.changes_inFoActive(false);
  }
}
