import { Component, OnInit } from '@angular/core';

@Component({
  selector: '.app-vlt',
  templateUrl: './vlt.component.html',
  styleUrls: ['./vlt.component.scss']
})
export class VltComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
